<template>
  <van-dialog id="van-dialog" />
  <div class="ClassEvaluation">
    <div class="nav">
      <van-row>
        <van-col span="5">
          <div @click="back()">
            <van-icon name="arrow-left" />
          </div>
        </van-col>
        <van-col span="14">
          {{ lang.class_evaluation }}
        </van-col>
        <van-col span="4">
          <div @click="confirm">
            <van-button
              type="primary"
              size="small"
              style="width: 100%; border-radius: 5px"
              >{{ lang.publish }}</van-button
            >
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="content">
      <van-row>
        <van-col offset="1" span="22">
          <textarea
            class="msg"
            rows="10"
            v-model="feedback.content"
            :placeholder="lang.fill_in_class_rating"
          ></textarea>
        </van-col>

        <van-col offset="1" span="22" class="left">
          <van-uploader
            class="upload"
            accept="image"
            :before-read="beforeRead"
            :after-read="afterRead"
            v-model="imageList"
            max-count="9"
            :max-size="10 * 1024 * 1024"
            @oversize="onOversize"
            image-fit="contain"
            :before-delete="deleteImg"
            multiple
          />
        </van-col>

        <van-col offset="1" span="22" style="margin-top: 20px" class="center">
          <div
            v-if="feedback.video.length < 9"
            class="uploadVideo"
            style="width: 100%; text-align: center"
          >
            <van-uploader
              max-count="1"
              accept="video"
              multiple="false"
              compressed="false"
              preview-image="false"
              result-type="dataUrl"
              v-model="uploadVideo"
              :max-size="500 * 1024 * 1024"
              @oversize="VideoOversize"
              :before-read="VideobeforeRead"
              :after-read="VideoafterRead"
            >
              {{ lang.uploadVideo }} {{ feedback.video.length }}
            </van-uploader>
          </div>

          <div v-if="feedback.video.length > 0" class="right">
            <view v-for="video in feedback.video" :key="video">
              <van-icon
                color="#000"
                size="20px"
                name="cross"
                @click="deleteVideo(video)"
              />
              <video style="width: 100%" controls>
                <source :src="video.url" type="video/mp4" />
                <source :src="video.url" type="video/ogg" />
                您的浏览器不支持Video标签。
              </video>
            </view>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import Common from "../../library/Common";
import { useRouter } from "vue-router";
import request from "../../library/MyRequest";
import { Toast, Dialog } from "vant";

export default {
  name: "ClassEvaluation",
  setup() {
    const mediaType = {
      IMAGE: 1, //Image
      VIDEO: 2, //Video
    };
    const common = Common;
    common.chechLogin(router);
    const router = useRouter();
    const lesson_id = router.currentRoute.value.params.id;
    let state = reactive({
      lang: {
        langBtn: "",
        class_evaluation: "",
        fill_in_class_rating: "",
        publish: "",
        uploadVideo: "",
        justUpload: "",
        justUploadVideo: "",
        pictureSize: "",
        videoSize: "",
        submitSuccess: "",
        timeout: "",
        networkError: "",
        DeleteVideo: "",
        isDeleteVideo: "",
        confirm: "",
        cancel: "",
      },
      feedback: {
        feedback_id: null,
        content: "",
        video: [],
      },
      tempVideo: null,
    });

    const imageList = ref([]);
    const uploadVideo = ref([]);

    common.initLanguage(state.lang);
    state.lang.langBtn = common.getLanguageBtn();
    const toggleLanguage = function () {
      common.toggleLanguage();
      common.initLanguage(state.lang);
      state.lang.langBtn = common.getLanguageBtn();
    };

    const back = function () {
      router.go(-1);
    };

    const getFeedback = async () => {
      var res = await request.get(
        `lesson/feedback/?lesson_id=${lesson_id}&is_teacher=1`
      );
      if (res.status == 200) {
        if (res.data.length > 0) {
          let data = res.data[0];
          state.feedback.feedback_id = data.id;
          state.feedback.content = data.content;
          for (var media of data.media) {
            if (media.media_type == mediaType.IMAGE) {
              imageList.value.push({
                url: media.url,
                media_type: media.media_type,
              });
            } else if (media.media_type == mediaType.VIDEO) {
              state.feedback.video.push({
                url: media.url,
                media_type: media.media_type,
              });
            }
          }
        }
      }
    };

    const onOversize = () => {
      Toast(state.lang.pictureSize);
    };

    const beforeRead = (File) => {
      const chenkFile = function (file) {
        return file.type == "image/png" || file.type == "image/jpeg";
      };
      if (File.length > 0) {
        //多文件
        for (let item of File) {
          if (!chenkFile(item)) {
            Toast(state.lang.justUpload);
            return false;
          }
        }
      } else {
        //单文件
        if (!chenkFile(File)) {
          Toast(state.lang.justUpload);
          return false;
        }
      }
      return true;
    };

    const afterRead = async (event) => {
      const uploadImage = async (file) => {
        var uploadFile = {
          url: null,
          name: null,
          media_type: mediaType.IMAGE,
          error: null,
        };
        const formData = new FormData();
        formData.append("file", file);
        try {
          var res = await request.upload(formData);
          if (res.status == 200) {
            uploadFile.url = res.data.url;
            uploadFile.name = file.name;
            return uploadFile;
          } else {
            uploadFile.error = `upload images https res.status:${res.status}`;
          }
          return;
        } catch (e) {
          uploadFile.error = e.message;
          console.log(e.message);
        }
        return uploadFile;
      };
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
      });
      if (event.length > 0) {
        for (let item of event) {
          imageList.value.pop();
        }
        for (let item of event) {
          let uploadFile = await uploadImage(item.file);
          if (uploadFile.error == null) {
            imageList.value.push(uploadFile);
          } else {
            Toast.clear();
            Toast(uploadFile.error);
          }
        }
      } else {
        imageList.value.pop();
        let uploadFile = await uploadImage(event.file);
        if (uploadFile.error == null) {
          imageList.value.push(uploadFile);
        } else {
          Toast.clear();
          Toast(uploadFile.error);
        }
      }
      Toast.clear();
    };

    const deleteImg = function (proxy) {
      return true;
    };

    const VideoOversize = () => {
      Toast(state.lang.videoSize);
    };

    const VideobeforeRead = (File) => {
      if (File.type == "video/mp4" || File.type == "video/quicktime") {
        return true;
      } else {
        Toast(state.lang.justUploadVideo);
        return false;
      }
    };

    const VideoafterRead = async (event) => {
      Toast.loading({
        message: state.lang.uploading,
        forbidClick: true,
        overlay: true,
        duration: 0,
      });
      const formData = new FormData();
      formData.append("file", event.file);
      try {
        var res = await request.upload(formData);
        if (res.status == 200) {
          var uploadFile = {
            url: res.data.url,
            media_type: mediaType.VIDEO,
          };
          state.feedback.video.push(uploadFile);
        }
      } catch (e) {
        Toast(state.lang.timeout);
        console.log(e);
      }
      uploadVideo.value = [];
      Toast.clear();
    };

    const deleteVideo = (video) => {
      Dialog.confirm({
        title: state.lang.DeleteVideo,
        message: state.lang.isDeleteVideo,
        confirmButtonText: state.lang.confirm,
        cancelButtonText: state.lang.cancel,
      }).then(() => {
        var index = state.feedback.video.indexOf(video);
        state.feedback.video.splice(index, 1);
      });
    };

    const confirm = async () => {
      var send = {
        feedback_id: state.feedback.feedback_id,
        content: state.feedback.content,
        lesson_id: lesson_id,
        media: [],
      };
      for (var img of imageList.value) {
        send.media.push(img);
      }
      for (var video of state.feedback.video) {
        send.media.push(video);
      }

      var res = null;
      try {
        res = await request.post("lesson/feedback/bulk/", send);
        if (res && res.status == 200) {
          state.feedback.feedback_id = res.data.feedback_id;
          Toast({
            forbidClick: true,
            message: state.lang.submitSuccess,
            onClose: () => {
              back();
            },
          });
        }
      } catch (e) {
        Toast(state.lang.networkError);
        console.log(e);
      }
    };

    if (!lesson_id) {
      back();
    } else {
      getFeedback();
    }

    return {
      ...toRefs(state),
      common,
      toggleLanguage,
      back,
      confirm,
      uploadVideo,
      imageList,
      onOversize,
      beforeRead,
      afterRead,
      VideobeforeRead,
      VideoafterRead,
      deleteImg,
      VideoOversize,
      deleteVideo,
    };
  },
};
</script>

<style scoped>
.ClassEvaluation {
  width: 100%;
  height: 100%;
  background: #fff;
}

.msg {
  font-size: 28px;
  border: 0px;
  width: 100%;
}

.upload {
  --van-uploader-size: 200px;
}
.vanuploadVideo {
  --van-uploader-size: 100%;
}
.uploadVideo {
  width: 100%;
  height: 100px;
  line-height: 100px;
  border: 5px solid #4284fd;
  border-radius: 20px;
  color: #4284fd;
  font-size: 32px;
  font-weight: 600;
}
</style>